:root {
  --blue: rgb(16, 41, 76);
  --dark-blue: rgb(12, 21, 34);
  --dark-blue2: rgb(19, 32, 48);
  --royal-blue: hsl(215, 40%, 20%);
  --light-blue: #47a9ff;
  --lighter-blue: #8ec8fc;
  --blue2: rgb(8, 112, 216);
  --light-grey: #ccc;
  --yellow: rgb(255, 200, 0);
  --red: #942029;
  --darkGrey: #262626;
}

::selection {
  color: auto;
  background: none;
}

::-webkit-scrollbar {
  display: none;
}

@-moz-document url-prefix() {
  html,body{scrollbar-width: none; }
}

body {
  color: #fff;
  overflow-x: hidden;
  background: var(--darkGrey);
  font-family: roboto, sans-serif;
}

.darkBlue {
  background: var(--dark-blue);
}

.darkBlue2 {
  background: var(--dark-blue2);
}

.blue2 {
  background: var(--blue2);
}

.blue2 h2 {
  color: white;
}

#anim0 h2 {
  color: #fff;
  letter-spacing: 0;
  font-family: museo-slab, sans-serif;
  font-size: 33px;
  font-weight: 400;
  margin: 20px 0 0 0;
}

.blue2 a.tag {
  color: var(--blue2);
}

.darkBlueGradient {
  background: linear-gradient(
    0deg,
    rgba(8, 112, 216, 0) 0%,
    rgba(13, 22, 35, 1) 100%
  );
  /* padding-bottom: 600px; */
  position: relative;
  z-index: 2;
  /* opacity: 0; */
}

.blue2Gradient {
  background: linear-gradient(
    0deg,
    rgba(8, 112, 216, 0.7) 0%,
    rgba(8, 112, 216, 0.7) 20%,
    rgba(8, 112, 216, 1) 100%
  );
  position: relative;
  z-index: 2;
}

.overlay {
  width: 100%;
  height: 100%;
}

.lightGrey {
  background: var(--light-grey);
}

.royalBlue {
  background: var(--royal-blue);
}

.darkGrey {
  background: var(--darkGrey);
}

.blue {
  background: var(--blue);
}

body.none {
  background: var(--darkGrey);
}

.lightBlue {
  background: var(--light-blue);
}

#top.none.navSm  {
  background: rgba(16, 41, 76,0.2);
  backdrop-filter: blur(20px);
}

.white {
  background: white;
  color: var(--dark-blue);
}

.white a,
.white a:visited,
.white h2 {
  color: var(--gold);
}

.app {
  width: 100%;
  height: 100%;
  float: left;
  padding: 0 0;
}

.app.menuOn {
  /* overflow: hidden; */
  /* height: 100vh; */
}

.page {
  float: left;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

#core {
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  background: var(--darkGrey);
  float: left;
}


::-webkit-scrollbar {
  display: none;
}

h1 {
  font-size: 68px;
  margin: 0 0 0 0;
  line-height: 110%;
  font-weight: 300;
  font-family: museo-slab, serif;
  font-style: normal;
}

h2 {
  font-size: 18px;
  color: var(--lighter-blue);
  letter-spacing: 5px;
  margin: 0 0 80px 0;
  padding: 0;
  font-weight: 700;
  line-height: 120%;
}

.context {
  color: #fff;
  font-weight: 700;
  letter-spacing: 3px;
}

h3 {
  font-size: 30px;
  font-weight: 500;
  margin: 10px 0 15px 0;
  padding: 0;
  line-height: 120%;
}

#detail h1 {
  font-size: 44px;
  /* font-weight: 400; */
  margin: 0px 0 20px 0;
}

#detail h2 {
  font-weight: 400;
  letter-spacing: 0;
  font-size: 22px;
  color: #fff;
  margin: 20px 0 40px 0;
}

h4 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 20px 0;
  padding: 0;
  font-family: museo-slab, sans-serif;
}

a,
a:visited {
  color: #fff;
  text-decoration: none;
}

p {
  line-height: 140%;
  font-size: 16px;
  font-weight: 400;
}


#homeCaseStudies h3 {
  font-family: museo-slab, sans-serif;
  width: 86%;
}

/* Transitions */
/* 
.page {
  opacity: 0;
} */

.pageItem-enter {
  opacity: 0;
}

.pageItem-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease;
}
.pageItem-exit,
.subPageItem-enter-done {
  opacity: 1;
}

.subPageItem-enter {
  opacity: 0;
}

.subPageItem-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease;
}
.subPageItem-exit,
.subPageItem-enter-done {
  opacity: 1;
}

/* Page */

#top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 3;
  height: 80px;
  position: fixed;
}

#logo {
  padding: 0;
  width: auto;
  height: 32px;
  /* transition: all 0.3s ease; */
}

#logo path {
  fill: #fff;
}

.navSm #logo, .navSm #menuBtn {
  filter: drop-shadow(0px 1px 1px rgb(0 0 0 / 0.2));
}

#top.navSm {
  top: 0;
  position: fixed;
  backdrop-filter: blur(2px);
}

#homeLogo {
  width: 70vw;
  height: auto;
  position: absolute;
  top: -20px;
}

/* .navSm #top #logo {
  height: 15px;
}

.navSm #top #menuBtn {
  width: 24px;
  height: 24px;
} */


.menuWrap {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#signIn {
  margin: 0 0 0 20px;
  cursor: pointer;
  font-weight: 500;
}

#menuBtn {
  /* padding: 8px; */
  transition: opacity 0.2s ease;
  cursor: pointer;
  /* backdrop-filter: blur(5px); */
  width: 28px;
  height: 28px;
}

#menuBtn path {
  transition: all 0.3s ease;
  opacity: 1;
}

#menuBtn path.off {
  opacity: 0;
}

#menu {
  display: flex;
  width: 100vw;
  position: fixed;
  justify-content: center;
  align-items: flex-start;
  padding-top: 200px;
  z-index: -1;
  opacity: 0;
  top: 0;
  height: calc(100vh - 200px);
  transition: opacity 0.3s ease;
}

#menu.none {
  background: var(--red);
}

/* .menuOn  */

.menuOn footer {
  display: none;
}

.menuOn {
  height: 100vh;
  overflow-y: hidden;
}

.menuOn #anim0 {
  opacity: 0;
}


.menuOn #menu {
  z-index: 2;
  opacity: 1;
}

.menuOn #top.none.navSm {
  background: none;
}

.menuOn #menuBtn {
  background: none;
}

.column {
  width: 86%;
  padding: 0 7%;
  float: left;
}
.column#detail {
  height: 100%;
}

.column50 {
  width: 50%;
}

.full {
  float: left;
  width: 100%;
}

.row {
  padding: 80px 0 80px 0;
  float: left;
  width: 100%;
  position: relative;
  z-index: 2;
}

.row#contact {
  z-index: 1;
}

.row#aboutIntro {
  padding-bottom: 20px;
}

.row.imageRow {
  padding: 0;
  height: 100vh;
  width: 100%;
}

.row.imageRow img {
  object-fit: cover;
  float: left;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}



.outerRow {
  width: 100%;
  float: left;
}

#caseStudies.row {
  position: relative;
  z-index: 4;
}

.swiper.row {
  position: relative;
  z-index: 4;
  padding: 80px 0 80px 0;
}

#contact.row {
  padding-bottom: 100px;
}

.contactGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  z-index: 2;
  position: relative;
}

.off {
  opacity: 0;
}

footer {
  float: left;
  width: 100%;
  background: var(--darkGrey);
  position: relative;
  z-index: 2;
 
}


footer .row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #fff;
  margin-top: 40px;
  padding-top: 40px;
}

footer .address {
  text-align: right;
}

footer .address a {
  padding: 15px 0 0 0;
  float: left;
  width: 100%;
}

.pageGradient {
  z-index: 0;
}

.gradient1 {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    189deg,
    rgba(68, 162, 244, 1) 0%,
    rgba(41, 50, 66, 0) 50%,
    rgba(41, 50, 66, 0) 100%
  );
  position: fixed;
  top: 0;
  opacity: 0.5;
  z-index: 0;
}

.navSm .gradient1 {
  top: -50px;
}

@keyframes gradientChange {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-20deg);
  }
  75% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.clientSub .swiper-button-next, .clientSub .swiper-button-prev {
  background: rgba(8, 112, 216, 0.75);
}

/* Navs */

nav ul,
nav ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 42px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  z-index: 3;
}

nav ul li {
  padding: 14px 0;
}

ul.flat {
  padding: 0;
  margin: 0;
  float: left;
  display: flex;
  align-items: center;
}

ul.flat li {
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  margin: 0 40px 0 0;
  cursor: pointer;
}

ul.flat li.heading {
  cursor: default;
}

nav.sub {
  margin: 0 0 0 0;
  float: left;
  position: relative;
  margin-top: 75px;
  z-index: 3;
  width: 100%;
  padding: 15px 0 15px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
}

nav.sub ul,
nav.sub ul li {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
}

nav.sub ul li.heading {
  font-weight: 500;
  font-size: 22px;
}

.sub .on a {
  background: rgba(255, 255, 255, 0.075);
}

nav.sub .swiper-slide {
  width: auto;
}



nav.sub .swiper-container {
  min-width: 100%;
}

nav.sub .swiper-slide a {
  display: flex;
  text-align: left;
  height: 46px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  z-index: 3;
  padding: 10px 20px;
  border-radius: 10px;
  max-width: 220px;
}


nav.sub .swiper-slide a span {
  width: 100%;
  float: left;
}



.clientSub a {
  flex-direction: column;
}

nav.sub .swiper-slide a .clientName {
  font-weight: 600;
}

nav.sub .context {
  height: 66px;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin: 0 20px 0 0;
}

.arrowThin {
  width: 30px;
  height: 100%;
  margin-left: 20px

}

nav.sub .swiper-slide a span {
  width: 100%;
}
nav.sub .swiper-slide a img {
  width: 30px;
  height: auto;
  padding-right: 15px;
}
/* Intro */

.intros {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  text-align: left;
  justify-content: center;
  
}

.shortIntro {
  min-height: 48vh;
  max-height: 650px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.row.intros {
  padding: 0;
}

#caseStudy #detail {
  display: grid;
  grid-template-columns: 62% 30%;
  column-gap: 150px;
  padding-top: 30px;
}

#caseStudy #detail h3 {
  font-size: 18px;
  color: #fff;
  letter-spacing: 5px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  text-transform: uppercase;
}

.detailItem {
  float: left;
  width: 100%;
  margin: 20px 0 0 0;
}

.twoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  width: 100%;
  height: 100%;
}

.detailColumn {
  /* overflow: hidden; */
  display: flex;
  align-items: center;
}

.textColumn {
  opacity: 0;
  transition: all 0.3s ease;
  text-align: left;
  align-items: center;
  display: flex;
  /* flex-direction: column; */
  height: 100%;
  max-height: 61vh;
}

.pageReady .textColumn {
  opacity: 1;
}

.intro {
  height: auto;
  opacity: 1;
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.intro span {
  width: 86vw;
  left: 0;
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80vh;
  background: linear-gradient(
    180deg,
    rgba(10, 10, 10, 1) 0%,
    rgba(32, 32, 32, 0.8) 50%,
    rgba(32, 32, 32, 0.2) 100%
  );
  z-index: 2;
  opacity: 0.7;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80vh;
  object-fit: cover;
  z-index: 1;
}

.introBanner {
  position: relative;
  top: 0;
  left: 0;
  width: 86vw;
  z-index: 2;
  padding-left: 7%;
  padding-right: 7%;
  /* max-width: 1600px; */

}

.introBanner h1 {
  font-family: museo-slab, serif;
  font-style: normal;
  font-weight: 500;
  z-index: 2;
  position: relative;
  font-size: 53px;
  /* max-width: 1600px; */
}

#intro_1,
#intro_2 {
  opacity: 0;
  /* height: 0; */
}

.bannerText {
  position: relative;
  z-index: 4;
  width: 86%;
 /* display: grid;
 grid-template-columns: 100%; */
 /* justify-content: flex-start; */
}

.blocks {
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 80px 0 20px 0;
}

.blocks h4 {
  font-size: 28px;
  font-weight: 400;
  /* color: var(--;iogh); */
}


.art {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  /* cursor: grab; */
}

#worldmap {
  float: right;
  z-index:1;
  position: relative;
  width: 100%;
  height: 100%;
}

#worldmap svg {
  float: right;
  transition: all 0.5s ease;
  transform: scale(2.5) translate(-20%, 25%);
  z-index:1;
  position: relative;
  /* transform-origin: bottom bottom; */
  /* transform: translate(0%, 50%); */
  /* width: 50%;
  height: auto; */
}

#worldmap path.land {
  fill: rgba(68, 162, 244, 0.45);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

#worldmap path.lakes {
  /* fill: var(--blue); */
  fill: #122E53;
}

.blur {
  /* filter: url("#dropshadow"); */
}
/* 
.glow {
  filter: url('#glow');
} */

#worldmap .point {
  stroke: none;
  fill: #ddd;
  transition: opacity 0.3s ease;
  /* opacity: 0; */
}



#worldmap .point.on {
  stroke: #44a2f4;
  fill: var(--lighter-blue);
}

#worldmap .point#nyc {
  stroke: #44a2f4;
  fill: var(--lighter-blue);
}

.linePath {
  stroke: orange;
  stroke-width: 1px;
  opacity: 0.5;
  fill: none;
}

.backgroundImg {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  /* overflow-x: hidden; */
  display: none;
}

.backgroundImg svg {
  width: 100vw;
  height: 130vh;
  position: relative;
}

#background1 {
  /* opacity: 0; */
  transform: translate(0, -20%);
}

.backgroundImg svg#bigCircle {
  width: 300vh;
  height: 300vh;
  transform: translate(-100vh, -20vh);
  position: fixed;
  z-index: 3;
}

#Group_21 {
  transform-origin: 50% 50%;
}

.videoBanner {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.backgroundImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.moreBtn {
  display: flex;
  align-items: center;
  height: 100%;
  /* padding-left: 40px; */
  min-height: 400px;
  padding: 20px 40px 40px 40px;
}

.moreBtn svg {
  display: none;
}

/* Services */

#row1 {
  z-index: 3;
  position: relative;
  padding: 60px 0 100px 0;
  background: var(--dark-blue);
}

#anim0 {
  margin-top: 0px;
  height: 80vh;
  position: relative;
  z-index: 3;
  float: left;
  width: 100%;
}

#intro_0 {
  opacity: 0;
  height: 0;
}


#home {
  /* background: var(--royal-blue); */
 
  
}

.pageBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 140vh;
  /* background: linear-gradient(
    190deg,
    rgba(8, 112, 216, 1) 0%,
    rgba(12, 21, 34, 1) 100%
  ); */
  background: #21395A;
}

.outerRow .swiper {
  z-index: 3;
  /* width: calc(100vw + 40px); */
  padding: 0 0 0 0;
}



.outerRow .overlay {
  min-height: 150vh;
  position: absolute;
  float: left;
}

.services {
  display: grid;
  grid-template-columns: 40% 40%;
  column-gap: 100px;
  row-gap: 80px;
}

.service {
  display: flex;
  /* justify-self: start; */
  float: left;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 80px);
  padding: 20px 40px 40px 40px;
  background: var(--royal-blue);
  min-height: 340px;
  transition: all 0.3s ease;
  border-radius: 20px;
  box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}

.service:active {
  opacity: 0.7;
  transform: scale(0.98);
}

.service.last {
  background: none;
  /* width: auto; */
  /* padding: 0; */
}

.serviceIcon {
  margin: 20px 0 10px 0;
  transition: all 0.3s ease;
  max-height: 33px;
  height: 100%;
  width: auto;
}
.serviceIcon:active {
  transform: scale(0.9);
}

.service .textRow {
  margin: 10px 0 0 0;
}

.serviceImage {
  position: relative;
  opacity: 0;
  /* transition: all 0.3s ease; */
}

#serviceImage_2 img {
  height: 380px;
}

.serviceImage.ipad img {
  width: auto;
  height: 400px;
  border: 8px solid rgba(252, 252, 252, 0.8);
  /* transform: translateX(-10px) translateY(-10px); */
  border-radius: 20px;
}

.serviceImage.mobile {
  border: 2px solid #2a2a2a;
  border-radius: 34px;
}

.serviceImage.ipad {
  /* border: 2px solid #2a2a2a; */
  /* border-radius: 22px; */
}

.serviceImage.mobile img {
  width: auto;
  height: 400px;
  float: left;
  border: 4px solid #f6f9fc;
  /* transform: translateX(-10px) translateY(-10px); */
  border-radius: 20px;
  box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%), inset 0 -2px 6px 0 rgb(10 37 64 / 35%);
}

.serviceImage img,
.serviceImage video {
  /* width: 100%;
  height: auto; */
  border-radius: 10px;
  width: auto;
  height: 320px;
  object-fit: cover;
  /* transition: all 0.3s ease; */
  box-shadow: 0 50px 100px -20px rgb(0 0 0 / 55%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
  float: left;
  transform: scale(0.95);
}

#tsparticles {
  width: 1000px;
  height: 600px;
  background: none;
}

.serviceImage.svg {
  transform: scale(1) !important;
}

.svgGradient {
  opacity: 0;
  animation: pulse-animation 4s forwards;
  animation-delay: 3.5s;
}

.servicePos {
  /* position: absolute; */
}

.serviceImage.svg svg {
  box-shadow: none;
  height: auto;
  width: 100%;
}

path#usb-on {
  animation: dash 2s linear forwards;
  animation-delay: 2s;
  opacity: 0;
}

path#wifi {
  position: relative;
  animation: pulse-animation 1.5s;
  animation-delay: 2s;
}

path#wifi-2 {
  position: relative;
  animation: pulse-animation 1.5s;
  animation-delay: 1s;
}

@keyframes pulse-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 80;
    stroke-dashoffset: 0;
    opacity: 0;
  }
  100% {
    stroke-dasharray: 80;
    stroke-dashoffset: 80;
    opacity: 1;
  }
}

.serviceImage.kiosk video {
  height: auto;
  border: 5px solid rgba(30, 30, 30, 0.9);
  transform: scale(0.8);
}


.serviceImage.kiosk .servicePos, .servicePos.servicePosAbsolute {
  position: absolute;
}

.serviceImage svg .gear path {
  stroke-width: 1px;
  fill: rgba(12, 21, 34, 0.5);
  stroke: #44a2f4;
}
.serviceImage.gears {
  /* height: 100%; */
  transform: translate(-50px, -200px) scale(0.8);
}

/* .svggear {
  width: 80%;
  padding: 5%;
} */

#gear4 {
  display: none;
}

.popup #process {
  z-index: 1;
}

.popup .content {
  position: relative;
  z-index: 4;
}

.serviceImage video {
  height: auto;
  width: 100%;
  z-index: 1;
}

.learn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
  font-weight: 500;
  color: #fff;
  opacity: 0.5;
  /* color: rgba(255, 200, 0, 0.8); */
  /* color: #FF7814; */
  /* align-self: flex-end; */
}
.learn span {
  height: 18px;
  line-height: 100%;
  margin: 0 10px 0 0;
}
.learn path {
  /* fill:  rgba(255, 200, 0, 0.8) */
  fill: #fff;
}

.learn svg {
  height: 14px;
  width: auto;
}

/* Clients */

.swiper-wrapper {
  padding-left: 7%;
}

.swiper-slide {
}

.client {
  width: 100%;
  height: 100%;
  display: block;
  float: left;
}

.clients h4 {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  /* color: var(--blue); */
}

.clients p {
  font-size: 30px;
  line-height: 120%;
  margin: 30px 20px 0 10px;
}

.clientImage {
  position: absolute;
  z-index: 1;
  margin: 0 0 40px 0;
  border-radius: 20px;
  width: 100%;
  height: 420px;
  object-fit: cover;
  transition: all 0.3s ease;
  box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
}

#client_0 .clientImage {
  object-position: left;
}

.clientImage:active {
  opacity: 0.7;
  transform: scale(0.98);
}

.clientOverlay {
  position: relative;
  z-index: 2;
  width: calc(100% - 0px);
  height: 420px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  border-radius: 20px;
  background: linear-gradient(
    0deg,
    rgba(30, 67, 106, 1) 0%,
    rgba(30, 67, 106, 1) 20%,
    rgba(30, 67, 1066, 0) 100%
  );
  color: #fff;
}

#client_0 .clientOverlay {
  background: linear-gradient(
    0deg,
    rgba(30, 67, 106, 1) 0%,
    rgba(30, 67, 106, 1) 20%,
    rgba(30, 67, 106, 0) 100%
  );
}

#client_1 .clientOverlay {
  background: linear-gradient(
    0deg,
    rgba(30, 67, 106, 1) 0%,
    rgba(30, 67, 106, 1) 20%,
    rgba(30, 67, 106, 0) 100%
  );
}

.clientOverlay h3 {
  padding: 20px 0 5px 20px;
  font-size: 20px;
}

.tags {
  margin: 30px 0 0 10px;
  float: left;
  width: 100%;
}

a.tag {
  background: #eee;
  display: block;
  float: left;
  margin: 0 15px 15px 0;
  text-transform: lowercase;
  padding: 5px 15px;
  border-radius: 20px;
  line-height: 100%;
  font-size: 15px;
  /* box-shadow: 0 2px 3px rgba(0,0,0,0.15); */
  transition: all 0.5s ease;
  border: none;
}

a.tag:hover {
  background: #ddd;
  /* color: #fff; */
}

/* Tech */

#tech .flexRow {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 90%;
}

.tech {
  padding: 0 40px 40px 0;
  font-size: 26px;
}

.tech p {
  color: #aaa;
}

.techIcon {
  height: 30px;
  width: auto;
}

.techIcon path {
  fill: rgb(255, 255, 255);
  opacity: 0.75;
}

/* Contact */

#contact a,
.contactInfo p {
  font-size: 26px;
  margin: 0;
  font-weight: 400;
  width: 100%;
  float: left;
  line-height: 100%;
  transition: all 0.3s ease;
}

#contact a:hover {
  color: var(--lighter-blue);
}

.contactInfo {
  display: grid;
  grid-template-columns: 100px auto;
  align-items: center;
  float: left;
  margin: 15px 0 15px 0;
  width: 100%;
}

.contactInfo h4 {
  letter-spacing: 5px;
  font-size: 14px;
  font-weight: 700;
  color: var(--light-grey);
  margin: 0;
  padding: 0;
  height: 14px;
  font-family: roboto, sans-serif
}

/* Detail */

#process .column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}

#process h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 22px 0;
  color: #fff;
}

#process h4 {
  font-size: 38px;
  line-height: 110%;
}

.row.studyPages {
  overflow: hidden;
  padding: 80px 0 80px 0;
}

.studyPages figure {
  margin: 0;
  padding: 0;
}

.gridInner {
  padding: 0 0 15% 0;
  display: flex;
  row-gap: 40px;
  column-gap: 40px;
  flex-flow: wrap;
  align-content: space-around;
  transform: scale(1.5) translateX(0%) translateY(15%);
}

.studyPage {
  width: calc(calc(100% / 3) - 40px);
}

.studyPage.ipad {
  width: calc(calc(62% / 3) - 40px);
}

#detail .tags {
  margin: 30px 0 0 0;
}

/* .studuPage.kiosk */

.studyPage.studyPage-first,
.studyPage.studyPage-last {
  /* display: none; */
  width: calc(calc(62% / 3) - 40px);
  box-shadow: none;
}

.studyPage img,
.studyPage video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: left; */
  border-radius: 10px;
  box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
}

/* Contact */

.map-container.mapboxgl-map {
  height: 500px;
  width: 100%;
  /* position: absolute; */
  top: 0;
  float: left;
}

.marker {
  /* width: 20px;
    height: auto; */
}

.marker svg {
  width: 40px;
  height: 40px;
  /* width: 100%;
    height: 100%; */
}

.marker circle {
  fill: var(--light-blue);
  stroke: #fff;
  /* stroke-width: 10px; */
}

.bannerText u {
  text-decoration: none;
  position: relative;
  display: inline-block;
  line-height: 1em;
  padding: 0 3px;
}

.bannerText u::before {
  background-color: rgba(255,255,255,0.25);
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  height: 22%;
  top: auto;
  bottom: 0;
  transform: scaleX(0);
  /* transform: scaleX(1); */
  transition: transform 1s cubic-bezier(.15,.75,.4,1), opacity 0.25s ease;
  transform-origin: left bottom;
  animation: animateUnderline 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}

@keyframes animateUnderline {
  to {
    transform: scaleX(1);
  }
}

.bannerText .cta {
  position: relative;
  display: grid;
  grid-template-rows: 20px;
  grid-template-columns: auto 10px;
  column-gap: 10px;
  align-items: center;
  margin: 40px auto 0 auto;
  background: var(--blue2);
  padding: 15px 25px;
  /* border-bottom: 2px solid #fff; */
  border-radius: 40px;
  transition: all 0.3s ease;
  color: #fff;
  cursor: pointer;
  width: auto;
  float: left;
  border: 1px solid transparent;
  /* opacity: 0.5; */
  /* display: none; */
   box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}

.cta svg {
  width: 12px;
  height: auto;
}

.cta:hover {
  background: none;
  border: 1px solid rgba(255,255,255,1)
}

.cta path {
  /* fill: var(--blue2); */
}

.cta:hover path {
  fill: #fff;
}

.cta p {
  margin: 0;
  padding: 0;
  line-height: 90%;
  font-weight: 600;
  color: #fff;
  font-size: 18px;
}

.cta:hover p {
  color: #fff;
}

.cta.on {
  opacity: 1;
  left: 0;
  transition-property: opacity;
  transition-duration: 1.25s;
  transition-timing-function: ease;
  transition-delay: 0.75s;
}

.point, .point.pulse3 {
  /* opacity: 0; */
}

.point0 {
  display: none;
}

#worldmap .point.pointGroup2 {
  transition: opacity 0.5s ease 1.2s;
}

#worldmap .point.pointGroup3 {
  transition: opacity 0.5s ease 2s;
}


.pulse {
  animation: pulse 1.5s infinite;
  transform-origin: center center;
}

.pulse2 {
  animation: pulse 1.5s infinite 0.75s;
  transform-origin: center center;
  display: none;
}

.pulse3 {
  animation: pulse3 1.5s infinite 0.75s;
  transform-origin: center center;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(2.1);
    opacity: 0;
  }
}

@keyframes pulse3 {
  0% {
    stroke-width: 1;
    opacity: 1;
  }

  100% {
    stroke-width: 10;
    opacity: 0;
  }
}

/* Swiper */

.swiper-button-next {
  z-index: 3;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0 !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
  background: rgba(16, 41, 76, 0.75);
  border: 1px solid #fff;
  border-radius: 40px;
  width: 50px !important;
  height: 50px !important;
  transition: all 0.3s ease;
  top: calc(50% - 4px) !important;
  opacity: 0;
}

/* .swiper-button-prev,
.swiper-button-next {
  opacity: 0;
}

 */

 .pageReady .swiper-button-prev,
.pageReady .swiper-button-next {
  opacity: 1;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px !important;
  font-weight: 700;
}

#about {
  padding-top: 60px;
}

.aboutText {
  width: 90%;
  margin: 0 0 60px 0;
}


#about #detail {
 /* max-height: 60vh;
 overflow: visible; */
}

#about #detail h1 {
  font-size: 18px;
  color: #fff;
  letter-spacing: 5px;
  margin: 0 0 40px 0;
  padding: 0;
  font-weight: 800;
  line-height: 120%;
  text-transform: uppercase;
  font-family: "Ringside Regular SSm A", "Ringside Regular SSm B";
}

#about #detail h2 {
  font-size: 38px;
  font-weight: 500;
  margin: 10px 0 30px 0;
  padding: 0;
  line-height: 120%;
}

.quote {
  /* display: flex;
  align-items: center; */
}

.quote h3 {
  /* font-style: italic; */
  line-height: 140%;
  font-size: 26px;
  /* font-weight: 300; */
  
}

.processRow h2 {
  margin: 0 0 20px 0;
}

.imageRow .processRow {
  position: absolute;
  z-index: 3;
  text-align: left;
  width: 90%;
  left: 0;
  font-size: 48px;
  padding: 0 0 30px 0;
  top: 0px;
  width: 100%;
  /* opacity: 0; */
}

.processIntro {
  max-width: 82%;
  float: left;
  
}

.processes {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  position: relative;
  padding-top: 30px;
  float: left;
}

.processRow h3 {
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 100%;
}

.process {
  background: rgba(30, 47, 70, 0.86);
  padding: 25px 30px;
  width: 23%;
  box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%), inset 0 -2px 6px 0 rgb(10 37 64 / 35%);
}

.process h4 {
  font-weight: 500;
}

.process ul {
  border-top: 1px solid var(--lighter-blue);
  padding: 20px 0 0 0;
  list-style: none;
}

.process ul li {
  list-style-type: disc;
  margin: 0 0 0 15px;
}

.bios {
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 30px;
}

.bioRow {
  padding-top: 30px;
  display: grid;
  grid-template-columns: auto 300px;
  column-gap: 120px;
}

.bio {
  width: 280px;
  height: 280px;

}

.bio img {
  width: 100%;
  height: auto;
  object-fit: cover; 
  border-radius: 20px;
}


.bio h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0 0 0;
  padding: 0;
}

.bio p {
  margin: 0;
  padding: 0;
}

#history {
  padding: 80px 0 0 0;
  float: left;
}

#history h2 {
  margin: 0 0 30px 0;
}

.textRow {
  float: left;
  width: 100%;
  margin: 30px 0 0 0;
}

.servicesImages {
  width: 100%;
  height: auto;
}

#jigsaw {
  width: 40vw;
  height: auto;

}

#pieces {
  transform: scale(0.85);
  transform-origin: center center;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.jigSawPiece {
  transform-origin: center center;
  stroke-width: 0.25px;
  stroke: #44a2f4;
  fill: rgba(12, 21, 34, 0.5)
}

.jigSawPoint {
  stroke: none;
  fill: rgba(240, 0, 0);
  opacity: 0;
}

#piece1 path{
  /* fill: var(--blue2); */
  fill: rgba(12, 21, 34, 0.5)
}

#piece2 path{
  /* fill: rgba(240, 0, 0); */
  fill: var(--blue);
}

#piece3 {
  /* fill: var(--blue);   */
}

#piece4 {
  /* fill: rgb(204,0,0); */
}


#engage .intros {
  min-height: 61vh;
}

.engageSlider .lastBtn {
  display: block;
  width: 140px;
}

.svggear {
  width: 100%;
  height: auto;
}

.menuWrap .link {
  font-size: 18px;
  color: white;
  letter-spacing: 2px;
  font-weight: 700;
}

@media only screen and (max-width: 1240px) {

  h1,
  nav ul,
  nav ul li,
  #detail h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 18px;
  }

  h3,
  #contact a,
  #detail h2 {
    font-size: 22px;
  }

  h4 {
    font-size: 22px;
  }

  p {
    font-size: 16px;
  }


  .intro span {
    width: 90vw;
  
  }
   
  .intro span  h1 {
    max-width: 900px;
  }
 

  .row,
  .row.studyPages {
    padding: 50px 0;
  }

  /* .intro span {
    width: 100%;
    
  } */

  .menuWrap {
    width: 80px;
    justify-content: flex-end;
    display: block;
    cursor: pointer;
  }



  #menuBtn {
    float: right;
    cursor: pointer;
  }

  #top {
    height: 60px;
    position: fixed;
    left: 0;
    top: 0;
    width: 90vw;
    padding: 0 5vw;
    /* background: none; */
  }



  #top #logo {
    max-width: 100%;
    height: 100%;
    width: auto;
    max-height: 25px;

  }
  
  .column {
    width: 90%;
    padding: 0 5%;
  }

  #signIn {
    display: none;
  }

  /* #menu {
    padding-top: 100px;
  } */

  .column50 {
    width: 100%;
  }

  #process .item {
    width: 100%;
    margin: 0 0 30px 0;
  }

  

  .backgroundImg {
    height: 100vh;
  }

  .outerRow .overlay {
    min-height: 100vh;
  }

  .outerRow .swiper {
    z-index: 3;
    /* min-height: calc(120vh - 160px); */
  }

  .service {
    width: calc(95% - 80px);
    margin: 0 0 0 5%;
    min-height: 370px;
  }

  .client {
    float: left;
    width: 100%;
    margin: 0 5%;
    min-height: 380px;
  }

  .moreBtn {
    height: 300px;
  }

  .clientImage,
  .clientOverlay {
    height: 300px;
  }

  .client span,
  .clientImage {
    float: left;
  }

  nav.sub {
    width: 100%;
  }

  nav.sub .swiper-wrapper {
    padding-left: 5%;
  }

  #tech .flexRow {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
  }

  .map-container.mapboxgl-map {
    height: 38vh;
  }

  footer .row {
    flex-direction: column;
  }

  footer .address {
    text-align: left;
  }

  .serviceImage video {
    height: 310px;
  }

  .serviceImage img {
    height: 280px;
  }

  #serviceImage_2 img {
    height: 320px;
  }

  .serviceImage.ipad img {
    height: 380px;
  }

  .serviceImage.svg svg {
    height: auto;
    width: 100%;
  }

  .serviceImage.kiosk video {
    height: 1000px;
  }

  #pieces {
    transform: scale(0.8);
    transform-origin: center top;
  }

  .swiper-wrapper {
    padding-left: 3%;
  } 

  .aboutText {
    width: 94%;
  }

  .process {
    width: calc(33% - 90px);
  }

  .outerRow .swiper {
    /* min-height: 70vh; */
  }

  #radial-ellipse {
    transform: scale(1.1);
    transform-origin: left center;
  }

  .serviceImage.svg {
    padding: 5% 0 0 0;
  }


  .serviceImage.kiosk video {
    height: 800px;
    transform: translate(5%, 15%);
  }
 

}

@media only screen and (max-width: 900px) {

  h1,
  nav ul,
  nav ul li,
  #detail h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 16px;
  }

  h3,
  #contact a,
  #detail h2,
  #about #detail h2,
  #process h4 {
    font-size: 26px;
  }

  h4 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }


  #process .column {
    grid-template-columns: 100%;
  }

  
}

@media only screen and (orientation: portrait) and (max-width: 900px) {
  /* #anim0, .intros {
    height: 57vh;
  } */

  #top #logo {
    height: 35px;
  }
  
  #background1 {
    transform: translateY(-40%) scale(2.5);
  }
  .backgroundImg svg {
    height: 120vh;
  }

  .outerRow .swiper {
    /* min-height: calc(57vh - 160px); */
  }

  .serviceImage img {
    height: 200px;
  }

  .serviceImage.ipad img {
    height: 340px;
  }

  #serviceImage_2 img {
    height: 280px;
  }

  #pieces {
    transform: translateY(10%) scale(0.8);
    /* position: relative; */
  }

  .serviceImage video {
    height: 240px;
  }

  .serviceImage.svg svg {
    height: 340px;
    /* transform: translateY(30%); */
  }

  .serviceImage.kiosk video {
    height: 600px;
    transform: translate(-5%, 0%);
  }

  .servicesImages .serviceImage {
    padding: 0 0 0 0;
  }

  #caseStudy #detail {
    grid-column-gap: 100px;
  }

  #anim0 {
    height: 30vh;
  }

  .intro span {
    width: 90vw;
  }

  #engage .intros {
    min-height: 45vh;
  }

  .aboutText {
    width: 100%;
  }

  .bioRow {
    column-gap: 40px;
  }

  #process .column {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
  

  .process {
    width: calc(33% - 60px);
    padding: 20px 20px;
  }

  #history {
    padding: 60px 0 0 0;
  }

  .lastBtn {
    display: inline-block;
    width: 20px;
  }
  
  nav.sub .swiper-wrapper {
    padding-left: 7%;
  }
  
}


@media only screen and (orientation: landscape) and (max-width: 900px) {
  #top {
    height: 60px;
    position: fixed;
    left: 0;
    top: 0;
    width: 90vw;
    padding: 0 5vw;
  }

  .swiper-wrapper {
    padding: 0;
  }

  #top #logo {
    height: 20px;
  }

  h1,
  nav ul,
  nav ul li,
  #detail h1, .introBanner h1 {
    font-size: 30px;
  }

  #anim0 h2 {
    font-size: 20px;
  }


  #menu {
    padding-top: 50px;
  }

  /* .intros {
    height: 140vh;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
  } */

  nav.sub {
    top: 60px;
  }

  .twoColumn {
    width: 100%;
  }

  .serviceImage img, .serviceImage video {
    width: 100%;
    height: auto;
    /* transform: scale(0.7); */
    transform-origin: left top;
    max-width: 100%;
  }


  .serviceImage.kiosk video {
    width: auto;
    height: 100%;
    /* transform: scale(0.7); */
    transform-origin: left top;
    max-width: 100%;
  }

  #caseStudy #detail {
    column-gap: 40px;
  }

  .serviceImage.svg svg {
    width: 100%;
    height: auto;
    padding-top: 50px;
  }

  

  .map-container.mapboxgl-map {
    height: 50vh;
  }

  #worldmap svg {
    transform: translate(0, -0%) !important;
  }

  nav.sub {
    margin-top: 60px;
    top: 0;
  }

  #menu {
    height: 100vh;
  }

  .processes {
    gap: 20px;
  }

  .row.imageRow {
    height: 200vh;
  }

  .intro span {
    width: 90%;
  }

  #anim0 {
    height: 70vh;
    padding: 20px 0 20px 0;
  }

  .pageBackground {
    height: 150vh;
  }

  #engage .intros {
    min-height: 150vh;
  }

  #process .column {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    gap: 30px;
}

#process h4 {
  font-size: 30px;
}



}



@media only screen and (max-width: 600px) {
  

  nav.sub .swiper-wrapper{
    scroll-snap-type: none !important;
    padding-left: 5%;
  }
  #top {
    height: 50px;
  }
  #top #logo {
    height: 15px;
  }

  .menuWrap .link {
    font-size: 14px;
  }

 #top #menuBtn {
  width: 24px;
  height: 24px;
  }
  
  
  h1,
  nav ul,
  nav ul li,
  #detail h1, .introBanner h1 {
    font-size: 28px;
  }
  #process h3, .clientOverlay h3 {
    font-size: 16px
  }

  h2, #about #detail h1 {
    margin: 0 0 40px 0;
    letter-spacing: 4px;
    font-size: 14px;
  }

  h3,
  #contact a,
  #contact p,
  #detail h2, 
  .clients p, #anim0 h2 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }

  .learn, .learn span{
    height: 15px;
    font-size: 14px;
  }

  #anim0 {
    height: 50vh;
    padding-bottom: 80px;
  }

  .blocks {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: 1fr;
    margin: 40px 0 20px 0;
}

  #intro_0 {
    margin: 0;
  }

  .bannerText {
    width: 100%;
  }

  .bannerText .cta {
    margin: 25px 0 0 0;
    padding: 10px 15px;
  }

  .bannerText .cta p {
    font-size: 14px;
  }

  .intro span.introBanner {
    width: 90vw;
    padding: 0 5%;
  }

  #process .column {
    grid-template-columns: 100%;
  }

  #about #detail h2, #process h4 {
    font-size: 24px;
  }

  .processIntro {
    max-width: 100%;
  }
  .serviceIcon {
    max-height: 24px;
  }
  .service {
    min-height: 340px;
  }

  #homeCaseStudies h3 {
    width: 100%;
  }
   
  .service .textRow {
    margin: 0;
  }
  nav.sub {
    top: 0px;
    margin-top: 50px;
    margin-bottom: 0;
    backdrop-filter: none;
    padding: 10px 0 10px 0;
  }

  nav.sub .context {
    font-size: 13px;
    margin: 0;
    height: 56px
  }

  nav.sub .swiper-slide a {
    height: 36px;
    font-size: 14px;
    padding: 10px 15px;
  }


  .backgroundImg svg {
    width: 100vw;
    height: auto;
    /* height: calc(100vh + 100px); */
    position: relative;
  }
  

  #background1 {
    transform: translate(0, 0) scale(1.5);
  }

  .bios {
    padding-top: 30px;
    display: grid;
    grid-template-columns: 100%;
    row-gap: 30px;
    /* grid-column-gap: 30px; */
    /* column-gap: 30px; */
    }

    .bio {
      width: 80%;
      height: auto;
    }

  .gridInner {
    padding: 0 20px;
    display: block;
    transform: scale(1) translateX(0%) translateY(0%);
  }

  /* #anim0 {
    height: 76vh;
  } */

  .outerRow .swiper {
    /* min-height: calc(100vh - 250px); */
  }

  /* .intros {
    max-height: 100%;
    height: auto;
  } */
  .row.intros {
    padding: 30px 0;
  }

  .twoColumn {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0;
    width: 100%;
    /* width: 100%; */
  }

  .row,
  .row.studyPages {
    padding: 30px 0 60px 0;
  }
  .detailColumn {
    width: 100%;
  }
  .studyPages .studyPage {
    width: 100%;
    margin: 0 0 20px 0;
    padding: 0;
    float: left;
  }

  .studyPage.studyPage-first,
  .studyPage.studyPage-last {
    display: none;
    /* width: calc(calc(62%/2) - 40px);
    box-shadow: none; */
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }

  .textColumn {
    position: relative;
    z-index: 2;
    background: none;
    /* backdrop-filter: blur(30px); */
    transition: all 0.3s ease;
  }

  .detailColumn.imageColumn {
    position: relative;
    z-index: 1;
    overflow: visible;
    display: flex;
    align-items: center;
  }

  .pageReady .textColumn {
    padding-bottom: 20px;
  }

  .servicesImages {
    min-height: 360px;
    padding-bottom: 20px;
  }

  .serviceImage.ipad img {
    height: 300px;
  }
  #serviceImage_2 img,
  .serviceImage img,
  .serviceImage video {
    height: 240px;
  }

  .serviceImage video {
    width: 100%;
    height: auto;
  }

  .serviceImage.svg {
    width: 100%;
  }
  .serviceImage.svg {
    margin: -10% 0 0 0;
    padding: 0 0 0 0;
  }

  .serviceImage.svg svg {
    width: 100%;
    height: auto;
    /* transform: translateY(10%); */
  }

  #jigsaw {
    width: 90vw
  }

  .serviceImage.kiosk video {
    height: 400px;
    transform: translate(0, 0%);
  }

  .servicesImages .serviceImage {
    padding: 0% 0 0 0;
  }

  .svggear {
    width: 100%;
    height: auto;
  }

  .servicesImages {
    z-index: 1;
  }

  #tech .flexRow {
    width: 100%;
  }

  .tech {
    padding: 0 30px 30px 0;
  }

  .serviceImage.kiosk video {
    height: auto;
  }

  .shortIntro {
    min-height: 40vh;
  }

  .shortIntro #detail h1 {
    margin-top: 0;
  }

  #caseStudy #detail {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 0px;
    column-gap: 0px;
  }

  #detail h2 {
    margin: 20px 0 30px 0;
  }
  

  .shortIntro {
    max-height: 100%;
  }

  #detail .tags {
    padding-bottom: 30px;
    margin: 30px 0 20px 0px;
    float: left;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  .contactGrid {
    display: grid;
    grid-template-columns: 100%;
  }

  .contactInfo {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    align-items: center;
    row-gap: 10px;
    float: left;
    margin: 15px 0 30px 0;
    width: 100%;
  }

  #worldmap {
    top: 90px;
    left: 120px;
  }

  #about .shortIntro {
    margin-top: 50px;
  }

  
  #worldmap path.lakes {
    fill: #163960;
  }
  
  .aboutText {
    width: 100%;
  }

.bios {
  padding-top: 0px;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 30px;
}

.bioRow {
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0;
  row-gap: 30px;
}

.bio {
  width: calc(47% - 30px);
  height: auto;
  float: right;
  padding: 15px 0 0 15px;
}

.quote .bio h3 {
  font-size: 14px;
}

.quote .bio p {
  font-size: 13px;
}

.bio img {
  width: 100%;
  height: auto;
  object-fit: cover; 
}

.quote h3 {
  line-height: 140%;
  font-size: 18px;
  
}

  .process {
    background: rgba(30, 47, 70, 0.86);
    padding: 25px 30px;
    width: calc(100% - 60px);
    box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%), inset 0 -2px 6px 0 rgb(10 37 64 / 35%);
  }

  #about .row {
    padding-bottom: 0;
  }

  .row.imageRow {
    height: 200vh;
  }

  #dubai {
    display: none;
  }

  .darkBlueGradient {
    background: linear-gradient(
      0deg,
      rgba(13, 22, 35, 1) 0%,
      rgba(13, 22, 35, 1) 100%
    );
    /* padding-bottom: 600px; */
    position: relative;
    z-index: 2;
    /* opacity: 0; */
  }

  #pieces {
    /* transform: translateY(10%) scale(1); */
    transform-origin: center center;
  }

  .serviceImage.kiosk video {
    height: auto;
    width: 90%;
      transform: translate(5%, 5%);
  }


  .contactInfo p{
    width: 100%;
    float: left;
  }

  .lastBtn {
    display: inline-block;
    width: 20px;
  }

  #row1 .lastBtn {
    display: inline-block;
    width: 20px;
  }

  .lastSlide {
    width: 40px !important;
  }

  .engageSlider .lastBtn {
    display: inline-block;
    width: 20px;
  }
 

  #tech .flexRow {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
  }

  .tech {
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .techIcon {
    max-width: 60px;
    max-height: 30px;
  }
  .tech p {
    font-size: 13px;
  }

  #process .item {
    margin: 0 0 10px 0;
  }

  

  .process h4 {
    font-size: 20px;
  }
  
}
@media only screen and (max-width: 375px) {

  #detail h1, h1, nav ul, nav ul li {
    font-size: 24px;
  }

  #detail h2 {
    font-size: 18px;
  }

}